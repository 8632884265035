<template>
  <div>
    <!-- Navbar -->
    <navbar
      :class="$route.name === 'newSignIn' ? 'block lg:hidden' : ''"
      :logo-pro="$route.name === 'newSignIn' ? true : false"
      :dont-download-app="$route.name === 'newSignIn' ? true : false"
    />
    <div class="bg-dok bg-cover min-h-screen flex flex-col">
      <div class="flex-grow flex flex-col">
        <router-view v-slot="{ Component }">
          <component :is="Component" />
        </router-view>
      </div>
    </div>
  </div>
</template>

<script>
import navbar from "./layouts/navbar/index";
export default {
  components: {
    navbar
  }
};
</script>

<style lang="scss">
.bg-signin-doctor {
  background-image: url("/artwork/signin.png");

  background-repeat: no-repeat;
  background-position: right top;
  background-size: 100%;
}

.bg-signin-patient {
  background-image: url("/artwork/signin_patient.png");

  background-repeat: no-repeat;
  background-position: right 50%;
  background-size: 100%;
}

.swiproute-enter-active {
  transition: opacity 0.6s, transform 0.6s;
}
.swiproute-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.swiproute-enter,
.swiproute-leave-to {
  opacity: 0;
  transform: translateX(-30%);
}

.bg-dok {
  background-image: -webkit-image-set(
    url("/home/body/Decorations.png") 1x,
    url("/home/body/Decorations@x2.png") 2x,
    url("/home/body/Decorations@x3.png") 3x
  );
  background-color: #fff;
  object-fit: contain;
}

.bg-grad {
  @apply bg-roseopacity;
  background-image: url("/art/header/group_55.png");
  background-repeat: no-repeat;
  border-radius: 8px;
}
.bg-navbar {
  @apply bg-roseopacity;
  background-image: url("/art/navbar/bg_652.png");
  background-repeat: no-repeat;
}

.bg-footer {
  @apply bg-roseopacity;
  background-image: url("/phone/mobapp.png");
  background-repeat: no-repeat;
  background-position: 92% bottom;
  background-size: 200px;
}

@screen lg {
  .bg-footer {
    @apply bg-roseopacity;
    background-image: url("/home/footer/footer@3x.png");
    object-fit: contain;
    background-repeat: no-repeat;
    background-size: 75%;
    background-position: 90% bottom;
  }
}

.bg-footer-ar {
  @apply bg-roseopacity;
  background-image: url("/phone/app_arabic.png");
  background-repeat: no-repeat;
  background-position: left 13% bottom 0px;
  background-size: 15%;
}
</style>


<template>
  <div
    :class="
      !deleteClasses
        ? 'bg-navbar bg-contain pt-5 pb-2 px-3 min-h-300'
        : otherClasses
    "
  >
    <template v-if="!noText">
      <h1 class="text-black text-3xl font-EffraR">
        {{ $t("patient.app_download") }}
      </h1>
      <span v-if="otherText" class="font-EffraR mt-2">
        {{ $t("patient.download_app_description") }}
      </span>
      <div class="font-EffraM text-lg mt-8 mb-3">
        {{ $t("patient.obtenir_un_lien") }}
      </div>
    </template>

    <div class="flex flex-col gap-y-2 mb-2">
      <div class="flex items-center relative w-full" dir="ltr">
        <dok-input
          size="lg"
          d-placeholder="6 64 56 34 32"
          :d-model.sync="inputPhoneNumber.value"
          :custom-class="inputPhoneNumber.error && 'border-red-500'"
        >
          <template v-slot:leftIcon>
            <span
              class="border-0 border-r-2 border-solid h-full w-full flex items-center justify-center font-EffraR text-gray-800 pr-2"
              :class="
                inputPhoneNumber.error ? 'border-red-500' : 'border-borderInput'
              "
            >
              +212
            </span>
          </template>
        </dok-input>
      </div>
      <button
        class="bg-rosedok text-white font-EffraM text-base uppercase border-0 rounded-cu px-3 py-5 cursor-pointer"
        @click="onSentSMS"
      >
        {{ $t("patient.sent_sms") }}
      </button>
    </div>
    <ul>
      <li class="text-base font-EffraR py-1 flex items-center">
        <img
          src="/art/google-play-line_1.png"
          srcset="
            /art/google-play-line_1@2x.png 2x,
            /art/google-play-line_1@3x.png 3x
          "
          alt="android"
          class="mr-1"
        />
        {{
          otherText
            ? $t("patient.googlePlay.one")
            : $t("patient.googlePlay.two")
        }}
      </li>
      <li class="text-base font-EffraR py-1 flex items-center">
        <img
          src="/art/apple-line_1.png"
          srcset="/art/apple-line_1@2x.png 2x, /art/apple-line_1@3x.png 3x"
          alt="ios"
          class="mr-1"
        />

        {{
          otherText ? $t("patient.appStore.one") : $t("patient.appStore.two")
        }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    noText: {
      type: Boolean,
      required: false
    },
    otherClasses: {
      type: String,
      required: false
    },
    deleteClasses: {
      type: Boolean,
      required: false
    },
    otherText: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      inputPhoneNumber: {
        value: null,
        error: false
      }
    };
  },
  methods: {
    onSentSMS() {
      if (!this.inputPhoneNumber.value) {
        return (this.inputPhoneNumber.error = true); // Validation Input Phone
      }

      this.inputPhoneNumber.error = false; // Validation Input Phone

      // Sent Request To the api
      this.$vs.loading(); // Start Loading
      this.$store.dispatch("user/SENT_SMS_DOWNLOAD_APP", {
        mobile: this.inputPhoneNumber.value,
        onData: ({ ok, message }) => {
          this.$vs.notify({
            time: 4000,
            text: message,
            color: ok ? "success" : "danger",
            iconPack: "feather",
            icon: !ok ? "icon-alert-triangle" : "icon-check-circle"
          });

          this.$vs.loading.close(); // Close Loading
        }
      });
    }
  }
};
</script>

<style></style>

<template>
  <div>
    <div
      class="bg-transparent fixed inset-0 h-full w-full z-4"
      v-if="dropdown.user"
      @click="dropdown.user = false"
    ></div>
    <!---------------------------------- Desktop Version ---------------------------------->
    <div class="bg-white">
      <div class="flex justify-between h-78 items-center container">
        <!-- Logo -->
        <div
          id="logo"
          class="flex flex-row items-center gap-x-2"
          :class="$route.name === 'newSignIn' ? 'w-full' : ''"
        >
          <i
            class="
              fas
              fa-bars
              text-2xl
              mr-5
              block
              xl:hidden
              cursor-pointer
              hover:text-rosedok
            "
            @click="sidebar = true"
          ></i>
          <router-link
            to="/"
            :class="
              $route.name === 'newSignIn'
                ? 'flex justify-center items-center w-full'
                : ''
            "
          >
            <img
              v-if="!logoPro"
              src="/logos/56/logo.png"
              alt="dok.ma"
              class="max-h-10"
            />
            <img
              v-if="logoPro"
              src="/logos/pro/pro@2x.png"
              alt="dok.ma"
              class="h-12"
            />
          </router-link>
        </div>
        <!-- End Logo -->

        <!-- Search -->
        <div
          v-if="
            $route.name === 'doctorsList' || $route.name === 'doctorsListActes'
          "
        >
          <div class="hidden lg:flex flex-row items-center gap-x-4">
            <div class="relative">
              <multiselect
                :searchable="true"
                :internal-search="true"
                :preserve-search="true"
                :multiple="false"
                v-model="search.citySelect"
                :options="citys"
                :placeholder="$t('patient.ville')"
                track-by="name"
                label="name"
                @input="onChangeCity"
                class="dokma-select-navbar"
              ></multiselect>

              <span
                class="
                  absolute
                  top-0
                  left-0
                  h-full
                  flex
                  items-center
                  justify-center
                  px-2
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="#ABABAB"
                    d="M10 17.416l4.125-4.125c.816-.815 1.371-1.855 1.596-2.986.225-1.132.11-2.305-.332-3.37-.441-1.066-1.189-1.977-2.148-2.618-.96-.641-2.087-.983-3.241-.983s-2.281.342-3.24.983c-.96.64-1.708 1.552-2.15 2.617-.44 1.066-.556 2.24-.331 3.37.225 1.132.78 2.172 1.596 2.987L10 17.416zm0 2.357L4.697 14.47c-1.05-1.05-1.764-2.386-2.053-3.84-.29-1.455-.14-2.963.427-4.334.568-1.37 1.529-2.541 2.762-3.366C7.067 2.106 8.517 1.666 10 1.666c1.483 0 2.933.44 4.167 1.264 1.233.825 2.194 1.996 2.762 3.366.568 1.37.716 2.879.427 4.334-.29 1.454-1.004 2.79-2.053 3.84L10 19.773zm0-8.94c.442 0 .866-.175 1.178-.488.313-.313.489-.737.489-1.179 0-.442-.176-.866-.489-1.178-.312-.313-.736-.488-1.178-.488-.442 0-.866.175-1.179.488-.312.312-.488.736-.488 1.178 0 .442.176.866.488 1.179.313.313.737.488 1.179.488zm0 1.667c-.884 0-1.732-.351-2.357-.976-.625-.626-.976-1.473-.976-2.358 0-.884.35-1.731.976-2.357.625-.625 1.473-.976 2.357-.976.884 0 1.732.351 2.357.976.625.626.976 1.473.976 2.357 0 .884-.35 1.732-.976 2.357-.625.626-1.473.977-2.357.977z"
                  />
                </svg>
              </span>
            </div>

            <div class="relative">
              <input
                class="
                  h-12
                  rounded
                  border border-solid
                  pl-10
                  pr-2
                  font-EffraR
                  text-base text-black
                "
                style="border-color: #e4e4e4"
                :placeholder="`( ${$t('patient.nom')},  ${$t(
                  'patient.specialite'
                )})`"
                v-model="search.query"
              />
              <span class="absolute left-0 top-0 h-full flex items-center px-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="#ABABAB"
                    d="M15.026 13.847l3.569 3.569-1.18 1.179-3.567-3.57c-1.328 1.065-2.98 1.644-4.681 1.641-4.14 0-7.5-3.36-7.5-7.5 0-4.14 3.36-7.5 7.5-7.5 4.14 0 7.5 3.36 7.5 7.5.002 1.702-.577 3.354-1.641 4.681zm-1.672-.618c1.058-1.088 1.648-2.545 1.646-4.063 0-3.223-2.61-5.833-5.833-5.833-3.224 0-5.834 2.61-5.834 5.833C3.333 12.39 5.943 15 9.167 15c1.517.002 2.975-.588 4.062-1.646l.125-.125z"
                  />
                </svg>
              </span>
            </div>

            <button
              class="border-0 bg-rosDok-ultra h-12 px-4 cursor-pointer rounded"
              @click="onSearch"
              :aria-label="$t('patient.search')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M11 19c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zM21 21l-4.35-4.35"
                />
              </svg>
            </button>
          </div>
        </div>
        <!-- End Search -->

        <!-- Download Button -->
        <button
          v-if="!dontDownloadApp"
          class="
            bg-rosedok
            text-white
            font-EffraM
            text-sm
            md:text-base
            border-0
            px-5
            py-3
            truncate
            uppercase
            rounded-cu
            cursor-pointer
            block
            xl:hidden
          "
          @click="getElemntDownload"
          :aria-label="$t('patient.app_download')"
        >
          {{ $t("patient.app_download") }}
        </button>
        <!-- End Download Button -->

        <!-- User Information -->
        <div
          id="actions"
          class="hidden xl:flex xl:flex-row xl:gap-x-3 items-center"
        >
          <me :logout="onLogout" />

          <button
            v-if="!$auth.isAuthenticated()"
            @click="$goTo('/login')"
            class="
              flex flex-row
              items-center
              bg-transparent
              border-2 border-solid border-dokBlue-ultra
              group
              rounded-50
              px-3
              py-2
              cursor-pointer
              mr-3
            "
            :aria-label="$t('patient.dok_pro')"
          >
            <!-- hover:bg-dokBlue-ultra -->
            <img
              src="/icons/shield-user-line1.png"
              alt="doctors"
              class="mr-2"
            />

            <div class="flex flex-col text-left">
              <span class="font-EffraM text-dokBlue-ultra">
                {{ $t("patient.dok_pro") }}
              </span>
              <span class="font-EffraR text-sm">
                {{ $t("patient.pour_les_praticiens") }}
              </span>
            </div>
          </button>
          <!--
            <button
              v-if="!$auth.isAuthenticated()"
              @click="$router.push('/login')"
              class="
                flex flex-row gap-x-2
                items-center
                bg-transparent
                border-2 border-solid border-rosDok-ultra
                rounded-50
                px-3
                py-2
                cursor-pointer
                mr-3
              "
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="#FD5B68" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 21v-2c0-1.06-.421-2.078-1.172-2.828C18.078 15.422 17.061 15 16 15H8c-1.06 0-2.078.421-2.828 1.172C4.422 16.922 4 17.939 4 19v2M12 11c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z"/>
              </svg>
              <div class="flex flex-col text-left">
                <span class="font-EffraM text-rosDok-ultra">{{  $("patient.patients") }}</span>
                <span class="font-EffraR text-sm">{{  $("patient.pour_les_patient") }}</span>
              </div>
            </button>
          -->
          <div class="relative">
            <select
              class="
                bg-transparent
                border-2 border-solid border-gray-400
                rounded-50
                pl-13
                pr-4
                py-3
                appearance-none
                text-lg text-black
                font-EffraM
                cursor-pointer
              "
              @change="$setLang"
              v-model="$defaultLang"
            >
              <option value="fr">Français</option>
              <option value="ar">العربية</option>
            </select>
            <span
              class="
                flex
                justify-center
                items-center
                absolute
                top-0
                bottom-0
                left-0
                px-3
              "
            >
              <img src="/img/global-line-1.png" alt="lang" />
            </span>
          </div>
        </div>
        <!-- End User Information -->
      </div>
    </div>
    <!---------------------------------- Mobile Version ---------------------------------->
    <navbar-mobile :sidebarActions.sync="sidebar" :onLogout="onLogout" />
  </div>
</template>

<script>
  import { EventBus } from "@/event-bus";
  import downloadApp from "./layouts/downloadApp.vue";
  import useApp from "./layouts/useApp.vue";
  import navbarMobile from "./mobile.vue";
  import algoliasearch from "algoliasearch/lite";
  import me from "./layouts/me.vue";

  export default {
    props: {
      logoPro: {
        type: Boolean,
        required: false,
      },
      dontDownloadApp: {
        type: Boolean,
        required: false,
      },
    },

    data() {
      return {
        sidebar: false,
        dropdown: { user: false },
        search: {
          citySelect: null,
          query: null,
        },
        citys: [],
        searchClient: algoliasearch(
          "2BJUOM1BDB",
          "7906f008c0346b78f39d051fa293387f"
        ),
        id: this.$auth.getUserId() || null,
      };
    },

    components: { downloadApp, useApp, navbarMobile, me },
    mounted() {
      if (this.$route.params.city) {
        this.search.citySelect = this.$route.params.city;
      }
      if (this.$route.params.query) {
        this.search.query = this.$route.params.query;
      }

      EventBus.$on("Emit_Success_Login", () => {
        this.$forceUpdate();
      });
    },

    created() {
      this.getAllCitys();
    },

    methods: {
      onSearch() {
        let query = {};

        if (this.city) {
          query.city = this.city.toLowerCase();
        }

        if (this.search.query) {
          query.query = this.search.query.toLowerCase();
          if (!this.city) {
            query.city = "maroc";
          }
        }

        this.$router.push({
          name: "doctorsList",
          params: {
            ...query,
          },
        });
      },

      async getAllCitys() {
        const index = this.searchClient.initIndex(
          "dokma_dev_cities_principal_first"
        );

        const data = await index.search("", {
          hitsPerPage: 618,
        });

        this.citys = data.hits;
      },

      onChangeCity(value) {
        this.city = value ? value.name : null;
      },

      async onLogout() {
        this.$performTracking({
          data: {
            id: this.id,
            route: "LOGOUT_AVATAR",
            action: "GET",
            data: {},
          },
        });
        this.$auth.logOut();
        window.location.pathname = "/";
      },

      getElemntDownload() {
        this.sidebar = true;
      },
    },
  };
</script>

<style>
.icon-size {
  width: 23.3px;
  height: 23.3px;
}

.swiproute-enter-active {
  transition: opacity 0.6s, transform 0.6s;
}
.swiproute-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.swiproute-enter,
.swiproute-leave-to {
  opacity: 0;
  transform: translateX(-30%);
}

/* Select City */
.dokma-select-navbar {
  min-width: 250px !important;
}

.dokma-select-navbar .multiselect__tags {
  height: 44px !important;
  padding-left: 25px !important;
}

.dokma-select-navbar .multiselect__tags {
  border: 1px solid #e4e4e4 !important;
}

.dokma-select-navbar .multiselect__select {
  height: 42px !important;
}

.dokma-select-navbar .multiselect__placeholder {
  padding-top: 4px !important;
  padding-left: 16px !important;
  color: #4f5968;
}

.dokma-select-navbar .multiselect__input,
.dokma-select-navbar .multiselect__single {
  line-height: 25px !important;
  @apply font-EffraR;
}

.dokma-select-navbar .multiselect__option--highlight {
  @apply bg-dokBlue-light;
}
.dokma-select-navbar
  .multiselect__option--selected.multiselect__option--highlight {
  @apply bg-rosDok-light;
}
</style>

<template>
  <dok-downdrop v-if="$auth.isAuthenticated()">
    <template v-slot:button>
      <div
        class="
          flex flex-row
          items-center
          cursor-pointer
          gap-x-1
          px-3
          py-1
          rounded-50
        "
        :class="
          $auth.getUserRole() === 'PATIENT'
            ? 'border-2 border-solid border-rosDok-ultra'
            : 'border-2 border-solid border-dokBlue-ultra'
        "
      >
        <img
          :src="$auth.getAvatar()"
          :title="$auth.getFullName()"
          onerror="javascript:this.src='/no-avatar.png'"
          class="rounded-full h-8 xl:h-[25px] w-8 xl:w-[25px] mr-1"
        />
        <div class="flex flex-col justify-start items-start">
          <h5
            class="font-EffraR text-base font-medium"
            :class="
              $auth.getUserRole() === 'PATIENT'
                ? 'text-rosDok-ultra'
                : 'text-dokBlue-ultra'
            "
          >
            {{ $auth.getFullName() }}
          </h5>
          <div
            v-if="$auth.getUserRole() === 'PATIENT'"
            class="text-[#3f3f3f] font-EffraR text-sm"
          >
            Mon compte
          </div>
          <div
            class="flex flex-col gap-y-1"
            v-if="$auth.getUserRole() === 'DOCTOR'"
          >
            <template
              v-if="
                $auth.getUserInfo().landingPage &&
                $auth.getUserInfo().landingPage.specialities.length
              "
            >
              <span style="color: #4f5968" class="font-EffraM text-left">{{
                $auth.getUserInfo().landingPage.specialities[0].name
              }}</span>
            </template>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:content v-if="$auth.getUserInfo()">
      <!--------------------------- DOCTOR ------------------------------------------>
      <ul v-if="role === 'DOCTOR' || role === 'TEAM-MEMBER'">
        <li
          @click="dokProClick"
          class="
            py-3
            px-1
            font-EffraR
            cursor-pointer
            text-base text-gray-800
            hover:bg-dokBlue-lighter hover:text-dokBlue-ultra
          "
        >
          <i class="fas fa-user-cog"></i> dok Pro
        </li>
        <li
          @click="settingsClick"
          class="
            py-3
            px-1
            font-EffraR
            cursor-pointer
            text-base text-gray-800
            hover:bg-dokBlue-lighter hover:text-dokBlue-ultra
          "
        >
          <i class="fas fa-user-cog"></i> Paramètres
        </li>
        <li
          @click="logout"
          class="
            py-3
            px-1
            font-EffraR
            cursor-pointer
            text-base text-gray-800
            hover:bg-dokBlue-lighter hover:text-dokBlue-ultra
          "
        >
          <i class="fas fa-door-open"></i> Se déconnecter
        </li>
      </ul>
      <!--------------------------- PHARMACY ------------------------------------------>
      <ul v-else-if="role === 'PHARMACY' || role === 'PHARMACY-MEMBER'">
        <li
          @click="$goTo({ name: 'pharmacyPrescription' })"
          class="
            py-3
            px-1
            font-EffraR
            cursor-pointer
            text-base text-gray-800
            hover:bg-dokBlue-lighter hover:text-dokBlue-ultra
          "
        >
          <i class="fas fa-user-cog"></i> dok Pharmacy
        </li>
        <li
          @click="$goTo('/pharmacy/setting')"
          class="
            py-3
            px-1
            font-EffraR
            cursor-pointer
            text-base text-gray-800
            hover:bg-dokBlue-lighter hover:text-dokBlue-ultra
          "
        >
          <i class="fas fa-user-cog"></i> Paramètres
        </li>
        <li
          @click="logout"
          class="
            py-3
            px-1
            font-EffraR
            cursor-pointer
            text-base text-gray-800
            hover:bg-dokBlue-lighter hover:text-dokBlue-ultra
          "
        >
          <i class="fas fa-door-open"></i> Se déconnecter
        </li>
      </ul>

      <!--------------------------- LABO ------------------------------------------>
      <ul v-else-if="role === 'LABORATORY' || role === 'LABORATORY-MEMBER'">
        <li
          @click="redirectTo('/laboratory/prescription')"
          class="
            py-3
            px-1
            font-EffraR
            cursor-pointer
            text-base text-gray-800
            hover:bg-dokBlue-lighter hover:text-dokBlue-ultra
          "
        >
          <i class="fas fa-user-cog"></i> dok Labo
        </li>
        <li
          @click="redirectTo('/laboratory/setting')"
          class="
            py-3
            px-1
            font-EffraR
            cursor-pointer
            text-base text-gray-800
            hover:bg-dokBlue-lighter hover:text-dokBlue-ultra
          "
        >
          <i class="fas fa-user-cog"></i> Paramètres
        </li>
        <li
          @click="logout"
          class="
            py-3
            px-1
            font-EffraR
            cursor-pointer
            text-base text-gray-800
            hover:bg-dokBlue-lighter hover:text-dokBlue-ultra
          "
        >
          <i class="fas fa-door-open"></i> Se déconnecter
        </li>
      </ul>

      <!--------------------------- PATIENT ----------------------------------->
      <ul v-else-if="role === 'PATIENT'">
        <li
          @click="redirectTo('/settings/profile')"
          class="
            py-3
            px-1
            font-EffraR
            cursor-pointer
            text-base text-gray-800
            hover:bg-dokBlue-lighter hover:text-dokBlue-ultra
          "
        >
          <i class="fas fa-user-cog"></i> Paramètres
        </li>
        <li
          @click="logout"
          class="
            py-3
            px-1
            font-EffraR
            cursor-pointer
            text-base text-gray-800
            hover:bg-dokBlue-lighter hover:text-dokBlue-ultra
          "
        >
          <i class="fas fa-door-open"></i> Se déconnecter
        </li>
      </ul>

      <!--------------------------- ANY ----------------------------------->
      <ul v-else>
        <li
          @click="logout"
          class="
            py-3
            px-1
            font-EffraR
            cursor-pointer
            text-base text-gray-800
            hover:bg-dokBlue-lighter hover:text-dokBlue-ultra
          "
        >
          <i class="fas fa-door-open"></i> Se déconnecter
        </li>
      </ul>
    </template>
  </dok-downdrop>
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  props: {
    logout: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      role: this.$auth.getUserInfo() ? this.$auth.getUserInfo().role : null,
    };
  },
  methods: {
    redirectTo(url) {
      window.location = url;
    },

    async dokProClick() {
      this.$performTracking({
        data: {
          id: this.$auth.getUserId(),
          route: "AGENDA_AVATAR",
          action: "GET",
          data: {},
        },
      });
      this.$router.push("/agenda");
    },

    async settingsClick() {
      this.$performTracking({
        data: {
          id: this.$auth.getUserId(),
          route: "SETTINGS_AVATAR",
          action: "GET",
          data: {},
        },
      });
      this.$router.push("/settings");
    },
  },
  mounted() {
    EventBus.$on("Emit_Success_Login", () => {
      this.$forceUpdate();
    });
  },
};
</script>

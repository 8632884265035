<template>
  <div>
    <div
      class="
        fixed
        xl:hidden
        inset-0
        bg-gray-800 bg-opacity-80
        top-0
        bottom-0
        w-full
        z-500
      "
      v-if="sidebar"
    ></div>
    <transition name="swiproute">
      <div
        class="
          fixed
          xl:hidden
          bg-white
          top-0
          bottom-0
          w-9/12
          overflow-auto
          scroll
          z-1000
          flex flex-col
        "
        :class="$defaultLang === 'ar' ? 'right-0' : 'left-0'"
        v-if="sidebar"
      >
        <!-- Logo Applcation -->
        <div class="flex justify-between items-center px-5 py-8">
          <router-link to="/">
            <img src="/logos/32/logo.png" alt="Dok.ma" />
          </router-link>
          <svg
            @click="sidebar = false"
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 32 32"
            class="cursor-pointer text-blackdok hover:text-rosDok-ultra"
          >
            <path
              d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"
            />
          </svg>
        </div>
        <div class="flex flex-col gap-y-8 flex-1">
          <div class="flex flex-col gap-y-2">
            <!-- Button Languges -->
            <div class="relative">
              <select
                class="
                  bg-transparent
                  border-0 border-solid border-gray-400
                  rounded-50
                  pl-13
                  pr-4
                  py-3
                  appearance-none
                  text-lg
                  font-EffraM
                  text-black
                  cursor-pointer
                "
                title="قريبا"
                @change="$setLang"
                v-model="$defaultLang"
              >
                <option value="fr">Français</option>
                <option value="ar">العربية</option>
              </select>
              <span
                class="
                  flex
                  justify-center
                  items-center
                  absolute
                  top-0
                  bottom-0
                  left-0
                  px-3
                "
              >
                <img src="/img/global-line-1.png" alt="lang" />
              </span>
            </div>
            <!-- Button DOK PATIENT -->
            <button
              class="
                flex flex-row
                items-center
                bg-transparent
                border-0
                rounded-50
                px-3
                pt-3
                pb-2
                cursor-pointer
              "
              v-if="!$auth.isAuthenticated()"
              @click="
                () => {
                  this.$router.push('/login');
                  this.sidebar = false;
                }
              "
            >
              <div class="icon-size mr-3">
                <img
                  src="/img/shield-user-line-1.png"
                  :alt="$t('patient.signin')"
                />
              </div>
              <div class="flex flex-col text-left">
                <span class="font-EffraM text-dokBlue-ultra text-base">
                  {{ $t("patient.dok_pro") }}
                </span>
                <span class="font-EffraR text-sm">
                  {{ $t("patient.pour_les_praticiens") }}
                </span>
              </div>
            </button>
            <!-- Information User Auth -->
            <me :logout="onLogout" class="px-3" />
            <!-- Button DOK PRO -->
          </div>
        </div>
        <!-- Download The Applcation -->
        <download-app id="downloadAppSideBar" :other-text="true"></download-app>
      </div>
    </transition>
  </div>
</template>

<script>
import downloadApp from "./layouts/downloadApp.vue";
import me from "./layouts/me.vue";

export default {
  props: {
    sidebarActions: {
      type: Boolean,
      required: true
    },
    onLogout: {
      type: Function,
      required: true
    }
  },
  computed: {
    sidebar: {
      get() {
        return this.sidebarActions;
      },
      set(value) {
        this.$emit("update:sidebarActions", value);
      }
    }
  },
  components: { downloadApp, me }
};
</script>